@import "newsstream";
@import "newsslick";

.content-laureat-inline {
  margin-bottom: 0.5rem;

  .laureat-prize {
    color: darken($gray-light, 10%);
  }
}
