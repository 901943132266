$slick-dot-size: 16px;

/* Slider */
// fixes flashing of hidden slides
.newsslider-teaser,
.newsslider-teaser-image,
.slider-home-teaser,
.slider {
  .item:nth-child(n+2) {
    display: none;
  }
}

.newsslider-teaser-image,
.slider-home-teaser,
.slider-home-news {
  .item {
    // min-height: 300px;
    // height: 100%;
  }
  .slick-slide > div {
    height: 100%;
  }
}

.slider-projects {
  .slick-slide {
    height: auto;
  }
}

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  height: 100%;

  &.slick-slider-draggable {
    user-select: none;
    touch-action: pan-y;
    tap-highlight-color: transparent;
  }
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }

  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
  height: 100%;
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;

  &:focus {
    outline: none;
  }

  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }

  .slick-loading & {
    visibility: hidden;
  }
}
.slick-slide {
  float: left;
  height: 100%;

  min-height: 1px;

  [dir="rtl"] & {
    float: right;
  }

  img {
    display: block;
  }

  &:focus {
    outline: none;
  }

  &.slick-loading img {
    display: none;
  }

  display: none;

  &.dragging img {
    pointer-events: none;
  }

  .slick-initialized & {
    display: block;
  }

  .slick-loading & {
    visibility: hidden;
  }

  .slick-vertical & {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
}
.slick-arrow.slick-hidden {
    display: none;
}

.slick-dots {
  position: absolute;
  bottom: 0px;
  left: 40%;
  z-index: 15;
  width: 80%;
  margin-left: -30%;
  margin-bottom: 4px;
  padding-left: 0;
  list-style: none;
  text-align: center;

  li {
    display: inline-block;
    width:  $slick-dot-size;
    height: $slick-dot-size;
    margin: 0px 5px;
    text-indent: -999px;
    border: 0;
    border-radius: 10px;
    cursor: pointer;
    background-color: transparentize($carousel-indicator-active-bg, 0.5);
  }
  .slick-active {
    width:  $slick-dot-size;
    height: $slick-dot-size;
    background-color: $carousel-indicator-active-bg;
  }

  button {
    display: none;
  }
}

$slick-arrow-width: 30px;
$slick-stream-nav-height: 50px;

.stream-nav-list {
  margin-left: $slick-arrow-width;
  margin-right: $slick-arrow-width;
  font-family: $font-family-sans-serif;


  @media (min-width: $screen-md-min) {
    margin-left: -$slick-arrow-width;
    margin-right: -$slick-arrow-width;

    &.project-stream-slider {
      margin-left: $slick-arrow-width;
      margin-right: $slick-arrow-width;
    }
  }

  .slick-arrow {
    bottom: 0;
    color: $brand-primary;
    cursor: pointer;
    font-size: $carousel-control-font-size;
    left: -$slick-arrow-width;
    line-height: $slick-stream-nav-height;
    position: absolute;
    text-align: center;
    text-shadow: $carousel-text-shadow;
    top: 0;
    vertical-align: middle;
    width: $slick-arrow-width;
    z-index: 1;

    @media (min-width: $screen-md-min) {
      left: -25px;
    }

    &.slick-disabled {
      color: $gray-lighter;
      cursor: auto;
    }

    &.arrow-left {
    }

    &.arrow-right {
      left: auto;
      right: -$slick-arrow-width;

      @media (min-width: $screen-md-min) {
        right: 0; // -$slick-arrow-width;
      }
    }
  }

  .slick-track {
    display: flex;
    justify-content: space-between;
  }

  .slick-current {
    .item {
      color: $brand-primary;
      font-size: 21pt;
      font-weight: bold;
      width: 100px;
    }

    &:focus {
      outline: none;
    }
  }

  .item {
    float: left;
    color: $gray-light;
    font-size: 16pt;
    line-height: $slick-stream-nav-height;
    text-align: center;
    vertical-align: bottom;


    &.stream-nav-upcoming {
      font-size: 15px;

      @media (min-width: $screen-sm-min) {
        font-size: 20px;
      }
    }
  }
}

.stream-nav-list {
  .slick-slide {
    cursor: pointer;

    &:hover {
      background-color: lighten($gray, 70%);
    }
  }
}

$slick-person-arrow-width: 60px;

.content-thumbnails,
.slider-detail,
.slider-hero-detail {
  .slick-arrow {
    bottom: 0;
    color: lighten($gray, 60%);
    cursor: pointer;
    font-size: 60px;
    left: -$slick-person-arrow-width / 3;
    // line-height: $slick-stream-nav-height;
    position: absolute;
    text-align: center;
    text-shadow: $carousel-text-shadow;
    top: calc(50% - 25px);
    vertical-align: middle;
    width: $slick-person-arrow-width;
    z-index: 1;
    opacity: 0;
    // @media (min-width: $screen-xs-min) {
    //   left: 0;
    // }

    &:hover {
      color: $brand-primary;
    }

    &.slick-disabled {
      display: none;
    }

    &.arrow-left {
    }

    &.arrow-right {
      left: auto;
      right: -$slick-person-arrow-width / 3;
    }
  }

  &:hover .slick-arrow {
    opacity: 1;
  }
}

.slider-hero-detail {
  .slick-arrow {
    opacity: 1;
  }
}

@import "persons";
