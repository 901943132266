.herounit-container {

  &.herounit-laureat[class*="col-"] {
    padding: 0;

    .herounit-image {
      padding: 0;
    }
  }
}
