.tabbed-media {
  padding: 0.5em 1em;
  background: desaturate(lighten($brand-primary, 50%), 33%);

  @media (max-width: $screen-sm-max) {
    .well {
      padding: 10px;
    }
  }
}

.tabbed-media__title {
  text-align: left;
}

.tabbed-media__description {
  font-size: 0.9em;
}

.tabbed-media__document-preview {
  display: none;
}

@media (min-width: $screen-md-min) {
  .tabbed-media__document-preview {
    font-size: 3em;
    display: block;
  }
}

.tabbed-media--small-margin {
  margin: 0.3em;
}

.tabbed-media__audio_tab {
  @media (max-width: $screen-sm-max) {
    .well {
      padding: 5px;
    }
    .media {
      margin-top: 5px;
    }

    .aplayer {
      max-width: 100%;
    }

    .aplayer-music {
      max-width: 60vw;
    }

    .aplayer .aplayer-info .aplayer-music .aplayer-title {
      font-size: 0.8em;
      white-space: normal;
    }

    .aplayer .aplayer-pic {
      width: 40px;
    }

    .aplayer .aplayer-info {
      margin-left: 35px;
    }
  }
}

.tabbed-media__image_tab,
.tabbed-media__document_tab {
  @media (max-width: $screen-sm-max) {
    .well {
      padding: 8px;
      font-size: 0.85em;
    }

    .media-left {
      display: block;

      img {
        margin: auto;
        padding-bottom: 1em;
      }
    }

    .media-body {
      overflow: scroll;
      max-width: 100%;
      display: block;
    }
  }
}

.tabbed-media__text_tab {
  @media (max-width: $screen-sm-max) {
    .well {
      padding: 8px;
      font-size: 0.85em;
    }
  }
}

.tabbed-media__control {
  cursor: pointer;
}

@media (min-width: $screen-md-min) {
  .tabbed-media__container {
    margin-top: 0.5em;
    margin-bottom: 1.5em;
  }
}

@media (max-width: $screen-sm-max) {
  .tabbed-media__block {
    margin-top: 0.5em;
    margin-bottom: 1.5em;
  }
}
