.img-fluid {
  width: 100%;
  max-width: 100%;
  height: auto;
}


$caption-bg-normal: transparentize($gray-lighter, 0.4);
$caption-bg-darker: transparentize(darken($gray-lighter, 15%), 0.25);
$caption-bg-highlight: transparentize(darken($gray-lighter, 20%), 0.2);

.Zcaption {
  // prefixed to avoid legacy collision
  background: $caption-bg-normal;
  transition: visibility 0.5s linear 0s, opacity .15s linear, background .15s linear;
  bottom: 0;
  color: $white;
  font-size: 0.9em;
  height: auto;
  padding: 0.5em;
  position: absolute;
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.35);
  width: 100%;

  @media (min-width: $screen-md-min) {
    font-size: 0.9em;
  }

  @media (min-width: $screen-lg-min) {
    font-size: 1.0em;
    padding: 0.75em;
  }

  @media (max-width: $screen-xs-max) {
    text-align: left;
  }

  @media (max-width: $screen-md-max) {
    background: $caption-bg-highlight;
    position: relative;
    height: 100%;
  }

  @media (min-width: $screen-md-min) {
    &:hover {
      background: $caption-bg-highlight;
    }
  }
}

.Zcaption.caption--darker {
  // prefixed to avoid legacy collision
  background: $caption-bg-darker;
  @media (max-width: $screen-md-max) {
    background: $caption-bg-highlight;
  }
  @media (min-width: $screen-md-min) {
    &:hover {
      background: $caption-bg-highlight;
    }
  }
}

.image-rights,
.caption__rights {
  text-align: right;
}

.caption__rights {
  margin-top: 0.33em;
  font-size: 0.90em;
  margin-bottom: -0.33em;
}


.Zcaption.caption--show-hover {
  display: none;
}

@media (min-width: $screen-md-min) {
  .Zcaption.caption--show-hover {
    opacity: 0;
    display: block;
  }

  .content-gallery:hover {
    .Zcaption.caption--show-hover {
      visibility: visible;
      opacity: 1.0;
    }
  }
}

.caption--bottom-spaced {
  @media (min-width: $screen-md-min) {
    padding-bottom: 30px;
  }
}

.caption__title,
.caption__text,
.caption__link {
  color: $white;
}

.caption__link:hover,
.caption__link:focus {
  color: $white;
  text-decoration: underline;
}

.caption__title {
  font-size: 140%;
  margin-top: 0;
  @media (min-width: $screen-md-min) {
    font-size: 170%;
  }
}

.caption__text {
  margin-bottom: 0;
}
