$person-arrow-padding: 50px;

.person-arrow {
  position: absolute;

  bottom: 0;
  color: darken($white, 5%);
  cursor: pointer;
  font-size: 40px;
  left: -10px;
  position: absolute;
  text-align: center;
  text-shadow: $carousel-text-shadow;
  top: 250px;
  vertical-align: middle;
  width: $slick-person-arrow-width;
  z-index: 1;

  @media (min-width: $screen-md-min) {
    left: $person-arrow-padding;
    font-size: 60px;
    top: calc(60vh - 60px);
  }

  &.slick-disabled {
    display: none;
  }

  &.arrow-left {
  }

  &.arrow-right {
    left: auto;
    right: -10px;

    @media (min-width: $screen-md-min) {
      right: $person-arrow-padding; // -$slick-person-stream-arrow;
    }
  }
}


$slick-person-arrow-width: 60px;

.slider-person-image {
  // padding: 0 20px;

  @media (max-width: $screen-sm-max) {
    padding: 0 20px;
  }

  .slick-track {
    transform-style: preserve-3d;
  }

  .item {
    z-index: 1;

    img {
      z-index: 1;
      transition: all 0.4s ease-in;
      //transform: translate3d(260px, 0, -1px) scale(0.7);
      margin: auto;
      height: 280px;
      width: 200px;

      @media (min-width: $screen-md-min) {
        height: 600px;
        width: 400px;
        //transform: translate3d(210px, 0, -1px) scale(0.7);
      }

      @media (min-width: $screen-lg-min) {
        height: 600px;
        width: 400px;
        //transform: translate3d(410px, 0, -1px) scale(0.7);
      }
    }

  }

  .slick-center .item {
    z-index: 2000;
    img {
      margin: 0 auto;
      transform: translate3d(0, 0, 0) scale(1.0);
      z-index: 2000;
    }
  }

  .slick-center + .slick-slide {
    .item {
      z-index: 1;
      img {
        z-index: 1;
        transform: translate3d(-155px, 0, -1px) scale(0.7);

        @media (min-width: $screen-md-min) {
          transform: translate3d(-210px, 0, -1px) scale(0.7);
        }
      }
    }
  }
}

$slick-person-stream-arrow: 30px;

.person-stream-slider {
  margin-left: $slick-person-stream-arrow;
  margin-right: $slick-person-stream-arrow;
  font-family: $font-family-sans-serif;

  .slick-arrow {
    bottom: 0;
    color: $brand-primary;
    cursor: pointer;
    font-size: $slick-person-stream-arrow;
    left: -$slick-person-stream-arrow;
    line-height: $slick-person-stream-arrow * 1.75;
    position: absolute;
    text-align: center;
    text-shadow: $carousel-text-shadow;
    top: 0;
    vertical-align: middle;
    width: $slick-person-stream-arrow;
    z-index: 1;

    @media (min-width: $screen-md-min) {
      // left: 0;
    }

    &.slick-disabled {
      color: $gray-lighter;
      cursor: auto;
    }

    &.arrow-left {
    }

    &.arrow-right {
      left: auto;
      right: -$slick-person-stream-arrow;
    }
  }

  .slick-track {
    display: flex;
    justify-content: space-between;
  }

  .item {
    float: left;
    color: $gray-light;
    font-size: 16pt;
    line-height: $slick-stream-nav-height;
    text-align: center;
    vertical-align: bottom;

  }

  .slick-current .item {
    color: $brand-primary;
    font-size: 21pt;
    font-weight: bold;
    width: 100px;

    &:focus {
      outline: none;
    }
  }

  .slick-slide {
    cursor: pointer;

    &:hover {
      background-color: lighten($gray, 70%);
    }
  }
}

// wagtail helper
.slider-persons {
  .item {
    margin-bottom: 4em;
    overflow-y: overlay;
  }
}
.wagtail-userbar.wagtail-userbar-absolute {
  right: 2em;
  bottom: -3em;
  position: absolute;
}
