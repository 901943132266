//
// Grid system
// --------------------------------------------------

@mixin my-make-grid-columns($i: 1, $list: ".col-xs-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}") {
  @for $i from (1 + 1) through $grid-columns {
    $list: "#{$list}, .col-xs-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}, .col-xl-#{$i}";
  }
  #{$list} {
    position: relative;
    // Prevent columns from collapsing when empty
    min-height: 1px;
    // Inner gutter via padding
    padding-left:  ceil(($gutter-sm / 2));
    padding-right: floor(($gutter-sm / 2));

    @media (min-width: $screen-md-min) {
      padding-left:  ceil(($gutter-lg / 2));
      padding-right: floor(($gutter-lg / 2));
    }
  }
}

// Container widths
//
// Set the container width, and override it for fixed navbars in media queries.

.container {

  @media (min-width: $screen-sm-min) {
    @include container-fixed($gutter-sm);
    width: $container-sm;
  }
  @media (min-width: $screen-md-min) {
    @include container-fixed($gutter-lg);
    width: $container-md;
  }
  @media (min-width: $screen-lg-min) {
    width: $container-lg;
  }
  @media (min-width: $screen-xl-min) {
    width: $container-xl;
  }
}


// Fluid container
//
// Utilizes the mixin meant for fixed width containers, but without any defined
// width for fluid, full width layouts.

.container-fluid {
  @include container-fixed($gutter-sm);

  @media (min-width: $screen-md-min) {
    @include container-fixed($gutter-lg);
  }
}


// Row
//
// Rows contain and clear the floats of your columns.

.row {
  @include make-row($gutter-sm);

  @media (min-width: $screen-md-min) {
    @include make-row($gutter-lg);
  }
}


// Columns
//
// Common styles for small and large grid columns

@include my-make-grid-columns;


// Extra small grid
//
// Columns, offsets, pushes, and pulls for extra small devices like
// smartphones.

@include make-grid(xs);


// Small grid
//
// Columns, offsets, pushes, and pulls for the small device range, from phones
// to tablets.

@media (min-width: $screen-sm-min) {
  @include make-grid(sm);
}


// Medium grid
//
// Columns, offsets, pushes, and pulls for the desktop device range.

@media (min-width: $screen-md-min) {
  @include make-grid(md);
}


// Large grid
//
// Columns, offsets, pushes, and pulls for the large desktop device range.

@media (min-width: $screen-lg-min) {
  @include make-grid(lg);
}


// X Large grid
//
// Columns, offsets, pushes, and pulls for the xlarge desktop device range.

@media (min-width: $screen-xl-min) {
  @include make-grid(xl);
}
