.modal-reel {
    visibility: hidden;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.75);
    z-index: 1111;
    overflow: hidden;

    #image2-reel {
        overflow: hidden;
        position: absolute;
        height: 90vh;
        width: 100%;
        top: 25vh;

        @media (max-width: $screen-sm-max) and (orientation: landscape) {
            top: 5vh;
        }

        @media (min-width: $screen-md-min) {
            height: 600px;
            width: 1024px;
            left: calc(50% - 512px);
            top: calc(50% - 300px);
        }
    }

    &.show {
        visibility: visible;
    }

    .modal-close {
      color: $white;
      opacity: 1;
      font-size: 28px;
      margin: 0 14px;
      text-shadow: 0 1px 0 #000;
      position: absolute;
      top: 3%;
      right: 3%;
      z-index: 11000;
    }
}
