
.breadcrumb {
  padding: 0;
  margin-bottom: 0;
  list-style: none;
  background-color: $breadcrumb-bg;
  border-radius: 0;
  float: left;

  > li {
    display: inline-block;
  }

  > .active {
    color: $breadcrumb-active-color;
  }

  > li {
    &:after {
      // [converter] Workaround for https://github.com/sass/libsass/issues/1115
      $nbsp: "\00a0";
      content: "#{$nbsp}|"; // Unicode space added since inline-block means non-collapsing white-space
      padding: 0 5px;
      color: $brand-primary;
    }

    &:before {
      content: "";
    }
  }


  > .active:after {
    content: "";
    padding: 0;
  }

  > li + li:before {
    content: "";
    padding: 0;
  }
}

.metadata {
  overflow: hidden;

  @media (min-width: $screen-md-min) {
    margin-top: 30px;
  }

  hr {
    margin-top: 14px;
    clear: both;
  }
}
