.map-googlemap {
  height: 100%;
  width: 100%;
  min-height: 400px;

  &.map-slim {
    height: 240px;
    min-height: 240px;
    margin-bottom: 10px;
  }
}

.content-funding-map {
  margin-top: 20px;
}

.content-recipient {
  margin-top: 0;
  font-size: 22px;

  @media (min-width: $screen-sm-min) {
    font-size: 23px;
  }

  @media (min-width: $screen-md-min) {
    font-size: 26px;
  }

  @media (min-width: $screen-xl-min) {
    font-size: 30px;
  }
}

.content-funding {
  span {
    white-space: nowrap;
  }
  font-size: 15px;

  @media (min-width: $screen-sm-min) {
    font-size: 16px;
  }

  @media (min-width: $screen-md-min) {
    font-size: 17px;
  }

  @media (min-width: $screen-xl-min) {
    font-size: 20px;
  }
}

.marker-box {
  font-family: $font-family-sans-serif;
  font-size: 15px;
  line-height: 1.2;

  .marker-year {
    color: $gray;
    font-size: 20px;
    padding-bottom: 4px;
  }

  .marker-recipient {
    padding-bottom: 4px;
  }

  .marker-short-desc {

  }
}

.funding-name {
  font-size: 18px;
  line-height: 1.2;
  padding-bottom: 4px;
}

.funding-amout {
  font-size: 15px;
  line-height: 1.2;
  padding-bottom: 4px;
}

.funding-recipient {
  font-size: 16px;
  line-height: 1.2;
  color: $gray;
}

.funding-media {
  margin-top: 0px;
  margin-bottom: 10px;

  .media-body {
    min-height: 1.4em;
  }

  @media (max-width: $screen-xs-max) {
    &.funding-media-first .media-body,
    &.funding-media-first .media-left {
      border-bottom: 1px solid $gray-light;
    }

    .media-body {
      padding-right: 10px;
    }
    .media-body.text-right {
      padding-left: 10px;
      padding-right: 0;
    }
  }
}

.content-filter-form {
  h4 {

  }

  @media (max-width: $screen-sm-max) {
    padding-bottom: 5px;
  }
}


.content-filter-box {

  &.content-filter-buttons a {
      min-width: 200px;
  }


  @media (max-width: $screen-sm-max) {
    width: 100%;
    display: block;
    text-align: right;
    padding-top: 5px;

    @media (min-width: $screen-sm-min) {
      &.content-filter-box-labeled {
        width: 48%;
        display: inline-block;
      }
    }

    &.content-filter-buttons a {
      min-width: 0;
      width: 48%;

      &:first-child {
        float: left;
      }
    }
  }

  @media (min-width: $screen-md-min) {
    padding-left: 10px;
  }

  label {
    font-size: 80%;
  }

}
@media (min-width: $screen-sm-min) {
  .content-filter-header {
    padding-top: 8px;
    display: block;
  }
}

.herounit-container {

  @media (min-width: $screen-md-min) {
    &.herounit-funding {
      padding-top: 10px;
    }
  }

  @media (max-width: $screen-sm-max) {
    &.herounit-funding {
      margin-top: 0;
    }
  }
}
