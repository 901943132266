@import "laureat";

.herounit-container {
  overflow: hidden;
}

.herounit {
  height: 100%;

  @media (min-width: $screen-sm-min) {
    display: flex;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  @media (min-width: $screen-md-min) {
    // margin-bottom: 10px;
    // margin-top: 10px;
  }

  @media (min-width: $screen-lg-min) {
    margin-top: 20px;
  }
}

.herounit-content {
  // background: $brand-primary;
  padding-top: $gutter-sm / 2;
  padding-bottom: $gutter-sm / 2;

  @media (max-width: $screen-xs-max) {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  @media (max-width: $screen-md-max) {
    padding-right: $gutter-sm / 4;
    padding-left: $gutter-sm / 4;
  }

  position: relative;
  flex-direction: row;
  display: flex;

  &.herounit-news {
    padding-left: 15px;
  }
  &.herounit-teaser {
    padding-right: 15px;
  }

  .content-gallery:before {
    content: "";
  }

  .caption.hero-caption {
    visibility: visible;
    opacity: 1.0;
    background: transparentize(darken($gray-lighter, 15%), 0.25);
    padding-bottom: 35px;

    @media (max-width: $screen-xs-max) {
      text-align: left;
    }

    @media (max-width: $screen-md-max) {
      background: transparentize(darken($gray-lighter, 20%), 0.25);
      position: relative;
      height: 100%;
    }

  }
  @media (min-width: $screen-md-min) {
    &:hover {
      a:before,
      .caption.hero-caption {
        visibility: visible;
        opacity: 1.0;
        background: transparentize(darken($gray-lighter, 20%), 0.15);
      }
    }
  }

  // @media (min-width: $screen-md-min) and (orientation: landscape) {
  //   .img-responsive {
  //     height: 100%;
  //   }
  // }

  @media (min-width: $screen-lg-min) {
    .img-responsive {
      // height: 100%;
    }
  }
}

// .herounit-content-large {
//   // @media (min-width: $screen-md-min) {
//     padding: $gutter-lg / 2;
//     padding-left: $page-padding-left-md;
//   // }

// }

.herounit-quote {
  border: none;
  color: $brand-light;
  font-size: 15pt;
  font-style: italic;
  font-weight: 300;
  line-height: 0.9;
  padding: 0;
  z-index: 0;

  @media (min-width: $screen-md-min) {
    font-size: 25pt;
    position: absolute;
  }

  @media (min-width: $screen-lg-min) {
    font-size: 35pt;
  }

  @media (min-width: $screen-xl-min) {
    font-size: 50pt;
  }
}

.herounit-item {
  align-self: flex-end;
  color: $white;
  overflow: hidden;
  // text-align: right;
  width: 100%;

  h2, h3, a {
    color: $white;
  }

  h2, h3 {
    font-size: 140%;
    margin-top: 0;

    @media (min-width: $screen-md-min) {
      font-size: 170%;
    }
  }

  p {
    margin-bottom: 0;
  }
}

.herounit-teaser .herounit-item {
  text-align: left;
  align-self: stretch;
  height: 100%;

  .slider-home-teaser {
    height: 100%;
  }
}

.herounit-image {
  background: $white;
  // TODO: scale in wagtail
  // max-height: 450px;
  // overflow: hidden;

  @media (max-width: $screen-sm-max) {
    padding: 0;
    // background: $brand-primary;
  }
}

.herounit-slim .herounit-image {
  // background: $brand-primary;
  padding-right: 0;
}

.no-flexbox {
  .herounit-content {
    height: 300px;
  }

  .herounit-item {
    margin-top: 300px;
  }
}

.balance-text {
  text-wrap: balanced;  /* Apply (proposed) CSS style */
}

.herounit-content-below {
  h2 {
    font-size: 22px;
    text-transform: uppercase;

    a {
      color: $headings-color;
    }
  }
}

.slider-hero-detail {
  .slick-slide {
    height: initial;
  }

  a.gallery-enlarge {
    display: block;
  }
}
