/*!
 * Dropdownhover v1.0.0 (http://bs-dropdownhover.kybarg.com)
 */
.dropdown .dropdown-menu .caret {
	border: 4px solid transparent;
	border-left-color: #000;
	float: right;
	margin-top: 6px;
}

.dropdown-menu {
  // min-width: 250px;
}
.dropdown-menu > li.open > a {
	background-color: #F5F5F5;
	color: #262626;
	text-decoration: none;
}
.dropdown-menu .dropdown-menu {
	left: 100%;
	margin: 0;
	right: auto;
	top: -1px;
}
.dropdown-menu-right .dropdown-menu,
.navbar-right .dropdown-menu .dropdown-menu,
.pull-right .dropdown-menu .dropdown-menu {
	left: auto;
	right: 100%;
}
.dropdown-menu.animated {
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
}
.dropdown-menu.animated:before {
  content: " ";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 99;
}
.dropdownhover-top {
	margin-bottom: 2px;
	margin-top: 0;
}
.navbar-fixed-bottom .dropdown-menu .dropdown-menu,
.dropdownhover-top .dropdown-menu {
	bottom: -1px;
	top: auto;
}

.navbar-nav > li > .dropdown-menu {
	margin-bottom: 0;
}

.dropdownhover-bottom {
	-webkit-transform-origin: 50% 0;
			transform-origin: 50% 0;
}
.dropdownhover-left {
	-webkit-transform-origin: 100% 50%;
			transform-origin: 100% 50%;
}
.dropdownhover-right {
	-webkit-transform-origin: 0 50%;
			transform-origin: 0 50%;
}
.dropdownhover-top {
	-webkit-transform-origin: 50% 100%;
			transform-origin: 50% 100%;
}
