.content-person-stream {
  @media (min-width: $screen-md-min) {
    margin-top: 28px;
    margin-bottom: 10px;
  }
}

.content-person {
  margin-top: 15px;
  margin-bottom: 10px;

  .content-full-title {
    font-size: 20px;
    hyphens: auto;
    text-transform: uppercase;
    font-weight: normal;

    @media (min-width: $screen-sm-min) {
      font-size: 23px;
    }

    @media (min-width: $screen-md-min) {
      font-size: 26px;
    }

    @media (min-width: $screen-xl-min) {
      font-size: 30px;
    }
  }

  .content-body {
    strong, b {
      color: $brand-primary;
    }
  }

  .content-intro,
  .content-author {
    font-size: 15px;
    margin-bottom: 20px;
  }

  .content-intro {
    color: $brand-primary;
  }

  .content-author {

  }

  .content-body {
    text-align: justify;
  }
}

.content-full .content-body .media-body {
  text-align: left;
}

.personalia-row {
  @media (max-width: $screen-xs-max) {
    div:nth-of-type(n+1) {
      clear: left;
    }
  }

  @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
    div:nth-of-type(2n+1) {
      clear: left;
    }
  }

  @media (min-width: $screen-lg-min) {
    div:nth-of-type(3n+1) {
      clear: left;
    }
  }
}

.personalia-large-row {
  @media (max-width: $screen-xs-max) {
    div:nth-of-type(n+1) {
      clear: left;
    }
  }

  @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
    div:nth-of-type(2n+1) {
      clear: left;
    }
  }

  @media (min-width: $screen-lg-min) {
    div:nth-of-type(2n+1) {
      clear: left;
    }
  }
}

.content-person {
  margin-bottom: 10px;

  img.media-object {
    width: 150px;
    height: 210px;
  }

  .media-heading {
    @media (min-width: $screen-sm-min) {
      font-size: 23px;
    }

    @media (min-width: $screen-md-min) {
      font-size: 26px;
    }

    @media (min-width: $screen-lg-min) {
      font-size: 22px;
    }

    @media (min-width: $screen-xl-min) {
      font-size: 30px;
    }
  }

  &.content-person-large {
    img.media-object {
      width: 195px;
      height: 273px;
    }
  }
}
