.content-stream {
  padding-left: 0;

  p {
    color: $brand-primary;
  }

  h4 {
    font-size: 16px;

    @media (min-width: $screen-md-min) {
      font-size: 16pt;
    }
  }

  a {
    color: $gray-dark;

    &:focus, &:visited {
      color: $gray;
    }
  }

  .stream-item {
    padding: 2px 10px 2px 10px;
    margin-bottom: 8px;
    position: relative;

    p {
      margin: 2px 0;
      font-size: 13px;
      line-height: 1.3;

      @media (min-width: $screen-md-min) {

      }
    }

    &:first-child .stream-date {
      margin-top: 0;
    }
  }

  &.homepage-stream {
    padding-left: 15px;
  }
}

.homepage-text {
  padding-right: 15px;
}

.stream-date {
  color: $gray-light;
  // font-family: $font-family-sans-serif;
  font-size: 12px;
  @media (min-width: $screen-md-min) {
    // margin-top: -13px;
  }
  // margin-bottom: -9px;
  text-align: right;
}

.stream-back {
  // float: left;
  clear: left;
  display: block;
}

.stream-intro-date {
  color: $gray-light;
}

.content-stream-compact {
  // margin-top: 14px;
}

.content-stream-large {
  margin-top: 19px;

  .stream-title {
    h1 {
      font-size: 20pt;
      text-transform: uppercase;
      margin-top: 10px;

      @media (max-width: $screen-xs-max) {
        font-size: 18px;
      }
    }
  }
}

.stream-title {
  h3 {
    text-transform: uppercase;

    @media (max-width: $screen-xs-max) {
      font-size: 18px;
    }
  }
}

.content-stream-header {
  margin-bottom: 15px;
}

.herounit-content-intro {
  color: $brand-primary;
}

.content-teaser-container {
  display: flex;
  overflow: hidden;
  width: 100%;
  flex-wrap: wrap;
  align-content: stretch;
}

.content-teaser-box {
  background: desaturate(lighten($brand-primary, 50%), 20%);
  padding: 10px;
  margin-bottom: 20px;
  min-height: 100px;
}

.news-stream-icon {
  color: $icon-gray;
  font-size: 35px;
  line-height: 1;
  position: absolute;
  right: 12px;
  top: 12px;
  width: 35px;
  font-weight: normal;
}

.stream-item.stream-item-iconed {
  padding-right: 60px;
}

.stream-item:nth-child(odd) {
  background: $brand-light;
}

.stream-item:nth-child(even) {
  background: $brand-gray;
}

.herounit-stream {
  .caption {
    padding-bottom: 35px;
  }
}

.event-details {
  margin-bottom: 15px;
}

.event-details-label {
  font-weight: bold;
}
