.grid {
  margin-top: 20px;

  .gallery-enlarge {
    width: 100%;
    height: 0;
    /* You define this doing height / width * 100% */
    position: relative;
    display: block;

    background: #b7b7b7 url("data:image/svg+xml,%3Csvg width='100px' height='100px' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' preserveAspectRatio='xMidYMid' class='lds-eclipse' style='background: rgb(183, 183, 183);'%3E%3Cpath ng-attr-d='%7B%7Bconfig.pathCmd%7D%7D' ng-attr-fill='%7B%7Bconfig.color%7D%7D' stroke='none' d='M30 50A20 20 0 0 0 70 50A20 22 0 0 1 30 50' fill='%23ffffff' transform='rotate(246 50 51)'%3E%3CanimateTransform attributeName='transform' type='rotate' calcMode='linear' values='0 50 51;360 50 51' keyTimes='0;1' dur='1s' begin='0s' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E") center center no-repeat;
  }

  .img-fitting {
    width: 100%;
    height: auto;
    position: absolute;
  }

  .caption, .Zcaption {
    z-index: 10;
    line-height: 1.1em;
  }

  .gallery-enlarge:before {
    z-index: 10;
  }

  .content-gallery {
    font-size: 9px;

    @media (min-width: $screen-lg-min) {
      font-size: 10px;
    }

    @media (min-width: $screen-xl-min) {
      font-size: 11px;
    }
  }

  .content-gallery.content-gallery--larger {
    font-size: 10px;

    @media (min-width: $screen-lg-min) {
      font-size: 11px;
    }

    @media (min-width: $screen-xl-min) {
      font-size: 13px;
    }
  }

  // lazy loading styles, grid specific
  img:not([src]) {
    visibility: hidden;
  }

  img {
    display: block;
    border: 0;
    opacity: 0;
  }

  img:not(.initial) {
    transition: opacity 1s;
  }

  img.initial,
  img.loaded,
  img.error {
    opacity: 1;
    pointer-events: none;
  }
}

.grid__item {
  margin-bottom: 10px;
}

.hidden-xl-down {
  display: none !important;
  @media (min-width: $screen-xl-min) {
    display: block !important;
  }
}
