.homepage-logo {
  text-align: center;
  margin-bottom: 10px;

  .img-responsive {
    display: inline-block;
  }
}

.homepage-content-text.content-text {

  h1, h2, h3 {
    margin-bottom: 30px;
  }

  h1 {
    font-size: 24px;
    text-transform: uppercase;
    text-align: left;

    @media (min-width: $screen-md-min) {
      font-size: 30px;
    }
  }

  p {
    margin-bottom: 20px;
    font-size: 110%;
  }
}

.Zherounit {
  overflow: hidden;

  @media (min-width: $screen-sm-min) {
    margin-bottom: 10px;
    margin-top: 10px;
  }

  @media (min-width: $screen-lg-min) {
    margin-top: 20px;
  }
}

.Zherounit .herounit__teaser {
  padding-top: $gutter-sm / 2;
  padding-bottom: $gutter-sm / 2;
  overflow: hidden;

  @media (max-width: $screen-xs-max) {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  @media (max-width: $screen-md-max) {
    padding-right: $gutter-sm / 4;
    padding-left: $gutter-sm / 4;
  }

  @media (min-width: $screen-sm-min) {
    &.herounit__teaser--right-padding {
      padding-left: $gutter-sm * 1.25;
      padding-right: $gutter-sm;
    }
  }

  @media (min-width: $screen-lg-min) {
    &.herounit__teaser--right-padding {
      padding-left: 50px;
      padding-right: 25px + 15px;
    }
  }

  @media (min-width: $screen-sm-min) {
    &.herounit__teaser--left-padding {
      padding-right: $gutter-sm * 1.25;
      padding-left: $gutter-sm;
    }
  }

  @media (min-width: $screen-lg-min) {
    &.herounit__teaser--left-padding {
      padding-right: 50px;
      padding-left: 25px+15px;
    }
  }
}

.Zteaser {
  // prefixed to avoid legacy collision
  display: flex;
  overflow: hidden;
  position: relative;
  flex-wrap: wrap;

  .swiper-wrapper {
    display: flex;
    width: 100%;
  }
}

.teaser__entry {
  flex: 0 0 100%;
  max-width: 100%;
}

.teaser__content {
  position: relative;
  overflow: hidden;
  height: 100%;

  font-size: 12px;

  @media (min-width: $screen-md-min) {
    font-size: 13px;
  }

  @media (min-width: $screen-lg-min) {
    font-size: 14px;
  }
}


.teaser__pagination {
  @media (min-width: $screen-md-min) {
    position: absolute;
  }

  @media (max-width: $screen-md-max) {
    background: $caption-bg-highlight;
  }

}
