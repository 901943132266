@media (min-width: $screen-md-min) {
  .content-search-header {
    margin-top: 3em;
  }
}

.search-result {
  margin-bottom: 1em;
  min-height: 6em;

  &:nth-child(2n+1) {
    clear: both;
  }
}

.content-search {

  .media-left {

    a {
      background: lighten($gray-lighter, 25%);
      display: inline-block;
      width: 260px;
      height: 180px;

      img {
        margin: auto;
        width: 260px;
        height: 180px;
      }
    }

    @media (min-width: $screen-md-min) {
      a {
        width: (260px * 0.75);
        height: (180px * 0.75);

        img {
          width: (260px * 0.75);
          height: (180px * 0.75);
        }
      }
    }

    @media (min-width: $screen-lg-min) {
      a {
        width: (260px);
        height: (180px);

        img {
          width: (260px);
          height: (180px);
        }
      }
    }

  }

  .media-heading {
    a {
      color: $gray-light;
    }
  }

  .media-body {
    .label {
      font-size: 0.9em;
    }
  }
}
